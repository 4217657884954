<template>
  <div class="catalog">
    <!-- page title -->
    <page-title></page-title>

    <!-- search bar -->
    <div class="row justify-center">
      <div class="col-6 search-field">
        <open-data-search-field></open-data-search-field>
      </div>
    </div>

    <!-- chosen filter section -->
    <v-container class="py-0">
      <dataset-chosen-filter></dataset-chosen-filter>
    </v-container>
    <!-- results section -->
    <v-container>
      <!-- filters -->
      <v-row class="px-md-4">
        <v-col class="col-sm-3 pl-sm-0" v-if="$vuetify.breakpoint.mdAndUp">
          <dataset-filter-section
            filterName="temi"
            filterType="topics"
            :items="themeList"
          ></dataset-filter-section>
          <dataset-filter-section
            filterName="tag"
            filterType="tags"
            :items="tagList"
          ></dataset-filter-section>
          <dataset-filter-section
            filterName="titolare"
            filterType="owners"
            :items="ownersList"
          ></dataset-filter-section>
        </v-col>

        <!-- mobile filters -->
        <v-col class="col-12 px-16" v-else>
          <v-list-group class="mobile-dropdown" v-model="filterOpen">
            <template v-slot:activator class="filter-button">
              <v-list-item-title>
                FILTRI
                <v-icon>filter_list</v-icon>
              </v-list-item-title>
            </template>
            <v-list-item>
              <dataset-filter-section
                filterName="temi"
                filterType="topics"
                :items="themeList"
              ></dataset-filter-section>
            </v-list-item>
            <v-list-item>
              <dataset-filter-section
                filterName="tag"
                filterType="tags"
                :items="tagList"
              ></dataset-filter-section>
            </v-list-item>
            <v-list-item>
              <dataset-filter-section
                filterName="titolare"
                filterType="owners"
                :items="ownersList"
              ></dataset-filter-section>
            </v-list-item>
          </v-list-group>
        </v-col>

        <v-col class="col-12 col-sm-9 px-8" id="dataset-results">
          <!-- dataset result header -->
          <dataset-result-header
            :datasetCount="datasetCount"
            :key="datasetCount"
          ></dataset-result-header>

          <!-- dataset result -->
          <div class="dataset-result">
            <v-row
              v-for="dataset in datasetList"
              :key="dataset.id"
              class="py-4 dataset-body"
            >
              <dataset-result-card :dataset="dataset"></dataset-result-card>
            </v-row>
          </div>

          <!-- dataset pagination -->
          <div
            class="dataset-pagintion"
            v-if="datasetCount > 0"
            @click="scrollToTop"
          >
            <v-pagination
              color="#008080"
              circle
              next-icon="keyboard_arrow_right"
              prev-icon="keyboard_arrow_left"
              :total-visible="5"
              :length="totalPages"
              v-model="page"
              @input="onChange"
              :key="datasetCount"
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import DatasetFilterSection from "../components/DatasetFilterSection";
import OpenDataSearchField from "../components/OpenDataSearchField";
import DatasetResultHeader from "../components/DatasetResultHeader";
import DatasetResultCard from "../components/DatasetResultCard";
import DatasetChosenFilter from "../components/DatasetChosenFilter";
import { getHomeTopicsList } from "../services/api";
import { animateValue } from "../boot/globals";
export default {
  name: "Catalogo",
  components: {
    PageTitle,
    OpenDataSearchField,
    DatasetFilterSection,
    DatasetResultHeader,
    DatasetResultCard,
    DatasetChosenFilter,
  },
  data() {
    return {
      page: 1,
      filterOpen: false,
    };
  },
  computed: {
    searchParams() {
      return this.$store.getters["getSearchParams"];
    },
    themeList() {
      return this.$store.getters["getDatasetTopicsList"];
    },
    tagList() {
      return this.$store.getters["getDatasetTagsList"];
    },
    ownersList() {
      return this.$store.getters["getDatasetOwnersList"];
    },
    datasetResult() {
      return this.$store.getters["getDatasetList"];
    },
    datasetList() {
      return this.datasetResult.datasets ?? [];
    },
    datasetCount() {
      return this.datasetResult?.totalResults ?? 0;
    },
    totalPages() {
      if (this.datasetCount) {
        return (
          Math.floor(this.datasetCount / 10) +
          (this.datasetCount % 10 == 0 ? 0 : 1)
        );
      } else {
        return 0;
      }
    },
    pageIndex() {
      return this.searchParams?.pageIndex + 1 ?? 1;
    },
    isVoidSearch() {
      return (
        this.searchParams.query == "" &&
        this.searchParams.tags.length == 0 &&
        this.searchParams.owners.length == 0 &&
        this.searchParams.topics.length == 0
      );
    },
    topicsList() {
      return this.$store.getters["getHomeTopicsList"];
    },
  },
  methods: {
    onChange() {
      this.$store.dispatch("setPageIndex", {
        pageIndex: this.page - 1,
      });
    },
    scrollToTop() {
      var elmnt = document.getElementById("dataset-results");
      elmnt.scrollIntoView();
    },
    searchAll() {
      this.$emit("setLoading", true);

      let payload = this.searchParams;
      payload.query = "*";

      this.$store.dispatch("setSearchParams", {
        searchParams: payload,
      });

      this.$emit("setLoading", false);
    },
    checkVisibility() {
      const el = document.querySelector(".dataset-header");
      const scroll = window.scrollY || window.pageYOffset;
      const boundsTop = el.getBoundingClientRect().top + scroll;

      const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
      };

      const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
      };

      if (
        (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
      ) {
        animateValue("dataset-count", 0, this.datasetCount, 500, 2);
        window.removeEventListener("scroll", this.checkVisibility);
      }
    },
  },
  async mounted() {
    if (this.isVoidSearch) {
      this.searchAll();
    }

    if (this.topicsList.length == 0) {
      let topicsListPromise = getHomeTopicsList();

      try {
        let { data } = await topicsListPromise;
        this.$store.dispatch("setHomeTopicsList", {
          topicsList: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    window.addEventListener("scroll", this.checkVisibility);
  },
  updated() {
    if (this.isVoidSearch) {
      this.searchAll();
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkVisibility);
  },
};
</script>

<style lang="scss">
button {
  outline: none;
}
.search-field {
  max-width: 900px;
}
.mobile-dropdown {
  text-align: center;
  border: 1px solid #eaeaea;
  border-radius: 5px;
}
.catalog .row{margin-top:0; margin-bottom: 0;}
</style>